<template>

  <!-- text -->
  <div v-if="contentSegment.type === 'text'">
    <div class="renderText" v-html="contentSegmentTextFormated">

    </div>
  </div>

  <!-- code line -->
  <!-- format uses: https://highlightjs.org/static/demo/ -->
  <div v-if="contentSegment.type === 'code'" class="codeblock">
    <pre v-highlightjs="sourcecode"><code v-bind:class="[contentSegment.format]">{{contentSegment.text}}</code></pre>
  </div>

  <!-- code -->
  <!-- format uses: https://highlightjs.org/static/demo/ -->
  <div v-if="contentSegment.type === 'codeblock'" class="codeblock">
    <pre v-highlightjs="sourcecode"><code v-bind:class="[contentSegment.format]">{{contentSegment.lines.join("\n")}}</code></pre>
  </div>


  <div v-if="contentSegment.type === 'listblock'">
    <ul>
      <li v-for="entry in contentSegment.entries" v-bind:key="entry">{{entry}}</li>
    </ul>
  </div>


  <div v-if="contentSegment.type === 'urlblock'">
    <div class="urlbox">
      <p>Further external information</p>
      <div v-for="entry in contentSegment.entries" v-bind:key="entry" class="urlboxRow">
        <div class="urlboxCellIcon"><a :href="entry.url"  target="_blank"><font-awesome-icon icon="fa-duotone fa-arrow-up-right-from-square" class="urlboxIcon" /></a></div>
        <div class="urlboxCellText"><a :href="entry.url"  target="_blank">{{entry.title}}</a></div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: ['contentSegment','correctAnswerIds'],

  data() {
    return {
      answerLetters: ['A','B','C','D','E','F','G','H'],
    }
  },


  computed: {
    correctAnswerLettersAsString() {
      let correctAnswerLetters = [];

      if (this.correctAnswerIds != undefined){
        this.correctAnswerIds.forEach(number => {
          correctAnswerLetters.push(this.answerLetters[number-1])
        })
      }

      let correctAnswerLettersAsString = correctAnswerLetters.join(" ");
      return correctAnswerLettersAsString
    },

    contentSegmentTextFormated(){
      let text = this.contentSegment.text

      text = text.replace("[CORRECT]", this.correctAnswerLettersAsString )

      text = this.escapeCharacters(text);

      text = text.replace(/`([^`]+)`/g, "<code class='codetext'>$1</code>");

      return text
    }
  },


  methods: {

    escapeCharacters: function(str){
      // Define a regular expression to match non-alphanumeric characters
      const regex = /[^a-z0-9`]/gi;

      // Define a replacement function to escape each non-alphanumeric character
      function replaceFn(match) {
        // Use the charCodeAt() method to get the decimal Unicode value of the character
        const code = match.charCodeAt(0);
        // Use the padStart() method to add leading zeroes to the hexadecimal representation of the code
        // const hex = code.toString(16).padStart(4, "0");
        // Use the \\u escape sequence to represent the character in the escaped string
        // return "\\u" + hex;
        return "&#"+code+";";
      }


      // Call the replace() method with the regular expression and the replacement function
      return str.replace(regex, replaceFn);
    }


  }




}
</script>