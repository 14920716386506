


export function areIntArraysEqual(ar1,ar2) {
    return JSON.stringify(ar1)==JSON.stringify(ar2);
}


// Vue often uses proxy. If you need the primnitive array
// this converts it.
export function proxyArrayToArray(ar){
    const res = []
    ar.forEach(a => {
        res.push(a)
    })

    return res
}